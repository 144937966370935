import { GetterTree } from 'vuex';
import { FeedbackState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<FeedbackState, RootState> = {
  isTransmitting(state) {
    return state.transmitting
  },
  isTransmitted(state) {
    return state.transmitted
  },
  isTransmittingError(state) {
    return state.transmittingError
  },
  getText(state) {
    return state.text
  }
}
