<template>
  <div>
    <h1>Itinerary Overview</h1>
    <div>
      <strong>Ship: </strong> {{tour.ship_call.cruise.ship.name}}
      <strong>Line: </strong> {{tour.ship_call.cruise.ship.line.name}}
      <br/>
      <strong>Date: </strong> {{tour.ship_call.cruise.start_date}}
      <strong>Cruise Code: </strong> {{tour.ship_call.cruise.code}}
    </div>
    <hr/>

    <b-list-group>
      <b-list-group-item v-for="(call, callIndex) in tour.itinerary" :key="call.id" v-if="call.tours.length > 0">
        <b-link v-b-toggle="'call-'+call.id">
          {{call.date}} - {{call.port.city.name}}
        </b-link>
        <b-collapse :id="'call-'+call.id" class="mt-2" :visible="callIndex==0">
          <div>
            <strong>Date: </strong> {{call.date}}
            <strong>Port: </strong> {{call.port.name}}
          </div>
          <ul class="m-0">
            <li v-for="(tour, tourIndex) in call.tours" :key="tour.id">
              <b-link v-b-toggle="'tour-'+tour.id">
                {{tour.code}} {{tour.name}}
              </b-link>
              <b-collapse :id="'tour-'+tour.id" class="mt-2" :visible="tourIndex==0">
                <ul>
                  <li v-for="inside of tour.insides" :key="inside.id">
                    <strong>Inside: </strong>{{inside.inside.name}}
                  </li>
                  <li v-for="pb of tour.pass_bys" :key="pb.id">
                    <strong>Pass By: </strong>{{pb.location.name}}
                  </li>
                  <li v-for="ps of tour.photo_stops" :key="ps.id">
                    <strong>Photo Stop: </strong>{{ps.location.name}}
                  </li>
                </ul>
              </b-collapse>
            </li>
          </ul>
        </b-collapse>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script lang="ts">
  import {Vue, Component} from 'vue-property-decorator'
  import {namespace} from "vuex-class"
  import _ from 'lodash'

  const tour = namespace('tour')
  @Component
  export default class TourItinerary extends Vue{
    @tour.Getter tour
  }
</script>
