<template>
  <div>
    <b-tabs content-class="mt-3" class="mt-3">
      <b-tab title="Tour">
        <div>
          <h4>Change Tour</h4>
          <b-form-group label="Key">
            <b-form-input v-model="tourChangeKey" />
          </b-form-group>
          <b-form-group label="Value">
            <b-form-input v-model="tourChangeValue" />
          </b-form-group>
          <b-button @click="onChangeTour">
            Submit
          </b-button>
        </div>
        <h4 class="mt-3">Debug</h4>
        <json-pretty :data="tour" :showLength="true" :deep="1" />
      </b-tab>
      <b-tab title="Vouchers">
        <json-pretty :data="vouchers" :showLength="true" :deep="2" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script lang="ts">
  import {Vue, Component} from 'vue-property-decorator'
  import {namespace} from 'vuex-class'
  import _ from 'lodash'
  const tour = namespace('tour')
  const tours = namespace('tours')
  const vouchers = namespace('tour/vouchers')
  @Component
  export default class TourDebug extends Vue {

    tourChangeKey: string = 'name'
    tourChangeValue: string = 'test'

    @tour.Getter tour
    @vouchers.Getter vouchers
    @tours.Action updateTour

    onChangeTour(){
      const data:any = {
        id: this.tour.id
      }
      _.set(data,this.tourChangeKey,this.tourChangeValue)
      this.updateTour(data)
    }
  }
</script>
