import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { FeedbackState } from './types';
import { RootState } from '../../types';

export const state: FeedbackState = {
  transmitted: false,
  transmitting: false,
  transmittingError: false,
  text: ""
};

const namespaced: boolean = true;

export const feedback: Module<FeedbackState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
