<template>
  <div class="imprint">
    <h1>Imprint</h1>

    <p>
      Time Change GmbH<br/>
      Kaiserdamm 88<br/>
      14057 Berlin, Deutschland<br/>
      +49 (0) 30 120 766 460<br/>
      stuff@time-change.com
    </p>

    <p>
      Design: Time Change GmbH<br/>
      Realisierung: Time Change GmbH<br/>
      Geschäftsführung: Marko Hafemann<br/>
      Registergericht: Amtsgericht Charlottenburg<br/>
      Registernummer: HR B 149636B<br/>
      Umsatzsteuer-Identifikationsnummer: DE290375617<br/>
    </p>

    <p>Verantwortlich für den Inhalt nach §55 Abs.2 RStV: Hanadi Siering, c/o Time Change GmbH, Kaiserdamm 88, 14057 Berlin.</p>

    <p>Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>


  </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    @Component
    export default class Imprint extends Vue {}
</script>

