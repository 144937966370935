<template>
  <div>
    <tour-voucher-modal />
    <div v-if="mainVoucher">
      <h4>Main</h4>
      <b-card class="p-3 mb-4" no-body>
        <tour-voucher :voucher="mainVoucher" />
      </b-card>
    </div>
    <div v-if="activityVouchers.length > 0">
      <h4>Activities</h4>
      <b-list-group class="shadow-sm  mb-4">
        <b-list-group-item v-for="voucher in activityVouchers" :key="voucher.id">
          <tour-voucher :voucher="voucher" />
        </b-list-group-item>
      </b-list-group>
    </div>
    <div v-if="staffVouchers.length > 0">
      <h4>Other Staff</h4>
      <b-list-group class="shadow-sm  mb-4">
        <b-list-group-item v-for="voucher in staffVouchers" :key="voucher.id">
          <tour-voucher :voucher="voucher" />
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator'
import {namespace} from 'vuex-class'
import TourVoucher from "../components/TourVoucher.vue"
import TourVoucherModal from "@/apps/guide/components/TourVoucherModal.vue";
import _ from 'lodash'

const tour = namespace('tour')
const vouchers = namespace('tour/vouchers')

@Component({
  components: {TourVoucherModal, TourVoucher}
})
export default class TourVouchers extends Vue {
  @tour.Getter tour
  @vouchers.Getter vouchers
  @vouchers.Getter activeVoucher
  @vouchers.Getter mainVoucher
  @vouchers.Getter mainVoucherId

  get activityVouchers(){
    return _.sortBy(this.vouchers.filter((v) => v.target != 'staff' && v.id != this.mainVoucher.id),"target_time")
  }

  get staffVouchers(){
    return _.sortBy(this.vouchers.filter((v) => v.target == 'staff' && v.id != this.mainVoucher.id),"target_time")
  }
}
</script>
