<template>
  <div>
    <b-row>
      <b-col md="9">
        <fa :icon="targetIcon" class="mr-1"/>
        <span v-if="voucher.target_time" class="mr-1">{{voucher.target_time | formatTime}}</span>
        <b class="mr-1">{{voucher.target_name}}</b>
        <b-badge variant="info" v-if="voucher.activities.length > 1" class="mr-1">
          Group Voucher
        </b-badge>
        <br/>
        <template v-if="voucher.activities.length == 1">
          <template v-for="attendance in voucher.activities[0].attendances">
            <span class="mx-1" v-if="attendance.quantity != null">
              {{ attendance.quantity }} <strong>{{ $const.ATTENDANCE_TYPES[attendance.type].label }}</strong>
            </span>
            <span v-else class="mx-1">
              <span class="alert-warning">Missing</span>
              <strong> {{ $const.ATTENDANCE_TYPES[attendance.type].label }}</strong>
            </span>
          </template>
        </template>
        <template v-else>
          <b-table-simple small class="mt-2 mb-2" striped hover>
            <b-thead>
              <b-tr>
                <b-th>Tour</b-th>
                <b-th>Status</b-th>
                <b-th v-for="(type,key) in attendanceTypes" :key="key">
                  {{$const.ATTENDANCE_TYPES[type].label}}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="activity in voucher.activities" :key="activity.id">
                <b-td>
                  <b-badge variant="info">{{activity.status}}</b-badge>
                  {{activity.tour_name}}
                  <b-badge variant="info" v-if="activity.tour_nr">{{activity.tour_nr}}</b-badge>
                  <b-badge variant="success" class="ml-2" v-if="activity.tour_id == tour.id">ours</b-badge>
                </b-td>
                <b-td>{{activity.status}}</b-td>
                <b-td v-for="attendance in activity.attendances" :key="attendance.id">
                  <span class="mx-1" v-if="attendance.quantity != null">{{attendance.quantity}}</span>
                  <span v-else class="alert-warning mx-1">Missing</span>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>
      </b-col>
      <b-col md="3" class="text-right">
        <b-badge :variant="statusBadgeVariant" class="mr-2">{{voucher.status}}</b-badge>
        <b-btn-group size="sm">
          <b-button disabled v-if="loading || voucher.status == 'sending'" size="sm">
            <b-spinner size="sm" style="width: 1rem; height: 1rem;"></b-spinner>
          </b-button>
          <b-button disabled v-if="voucher.status == 'submitted'">
            <fa icon="clock" />
          </b-button>
          <b-button
            v-if="isEditable"
            :disabled="loading"
            @click="onEditVoucher"
          >
            <fa icon="pen"></fa>
            Edit
          </b-button>
          <b-button
            v-if="isSubmittable"
            :disabled="loading"
            @click="onSubmitVoucher"
            variant="success"
          >
            <fa icon="paper-plane"></fa>
            Send
          </b-button>
        </b-btn-group>
        <div v-if="error" class="text-danger">
          {{error}}
        </div>
      </b-col>
    </b-row>

    <span v-if="!isSigned" class="alert-warning mx-1">
      Signature Missing
    </span>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator'
import {namespace} from 'vuex-class'
import {TourVoucherForm} from "@eguide/api"
import Guide from "@/apps/guide/guide";

  const tour = namespace('tour')
  const user = namespace('user')
  const vouchers = namespace('tour/vouchers')

  @Component
  export default class TourVoucher extends Vue {

    @Prop({required:true}) voucher!: TourVoucherForm

    loading: boolean = false
    error: string = null

    @tour.Getter tour
    @tour.Action refreshTour
    @vouchers.Action submitVoucher
    @vouchers.Action sendVoucher
    @vouchers.Mutation setActiveVoucherId
    @vouchers.Action updateVoucher
    @vouchers.Getter activeVoucher
    @vouchers.Getter mainVoucher
    @user.Getter staffId

    async onSubmitVoucher(){
      this.error = null
      this.loading = true
      try {
        await this.submitVoucher(this.voucher)
        Guide.sync.run()
      } catch(e) {
        const response = (<any>e).response
        this.error = response?.data?.error || e.message
      } finally {
        this.loading = false
      }
    }
    async onEditVoucher(){
      try {
        this.setActiveVoucherId(this.voucher.id)
        this.loading = true
        await this.refreshTour()
        if(!this.isEditable) {
          this.error = "Has already been sumitted by somebody else."
          return
        }
        this.$nextTick(() => {
          this.$bvModal.show('voucher-modal')
        })
      } catch(e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }

    get attendanceTypes(){
      return this.voucher.activities[0].attendances.map((a) => a.type)
    }

    get isEditable(){
      if(this.voucher.status == 'submitted' || this.voucher.status == 'sending' || this.voucher.status == 'retry') return false
      if(this.isMain) return true
      else if(!this.isMainSubmitted) return false
      else if(this.voucher.target == 'staff' && this.voucher.staff_id != this.staffId) return false
      else return this.voucher.status != "confirmed"
    }

    get isSubmittable(){
      if(!this.isFilledOut) return false
      if(!this.isSigned) return false
      if(this.voucher.status == 'submitted' || this.voucher.status == 'sending' || this.voucher.status == 'retry') return false
      if(this.voucher.status == 'confirmed') return false
      return true
    }

    get isMain(){
      // NOTE: does not work if no staff activity is present
      //return this.voucher.staff_id == this.staffId
      return this.voucher.id == this.mainVoucher.id
    }

    get isSubmitted(){
      return this.submittedStates.includes(this.voucher.status)
    }

    get isMainSubmitted(){
      return this.submittedStates.indexOf(this.mainVoucher.status) >= 0
    }

    get isSigned(){
      if(this.isMain){
        return !!this.voucher.staff_signature
      } else {
        return !!(this.voucher.staff_signature && this.voucher.counter_signature)
      }
    }

    get isFilledOut(){
      for(const activity of this.voucher.activities){
        if(activity.status != 'draft') continue
        for(const attendance of activity.attendances){
          if(attendance.quantity == null || attendance.quantity == undefined){
            return false
          }
        }
      }
      return true
    }

    get submittedStates(){
      return ['confirmed', 'revised']
    }

    get targetIcon() {
      switch (this.voucher.target) {
        case 'fnb':
          return 'fish'
        case 'inside':
          return 'landmark'
        case 'transport':
          return 'bus'
        case 'staff':
          return 'user'
      }
    }

    get statusBadgeVariant(){
      switch(this.voucher.status){
        case 'sending': return 'secondary'
        case 'warning': return 'warn'
        case 'confirmed': return 'success'
        case 'changed': return 'primary'
        case 'error': return 'danger'
        default: return 'info'
      }
    }
  }
</script>

<style scoped>

</style>
