import {Module} from 'vuex'
import {getters} from './getters'
import {actions} from "./actions"
import {mutations} from './mutations'
import {VouchersState} from './types'
import {RootState} from '../../types'

export const state: VouchersState = {
  activeVoucherId: null,
}

const namespaced: boolean = true

export const vouchers: Module<VouchersState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
