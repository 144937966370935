<template>
  <div>
    <h1>Today's team</h1>
    <b-table :items="tableItems" :fields="tableFields" striped fixed stacked="sm">
      <template #cell(tour)="{item}">
        {{item.code}} {{item.name}} <b-badge variant="info" v-if="item.tour_nr">{{item.tour_nr}}</b-badge>
      </template>
      <template #cell(staff)="{item}">
        <a :href="'tel:'+item.staff.contact.phone" v-if="item.staff">
          <fa icon="phone"/> {{item.staff.contact.name}} <b-badge variant="info">{{$const.STAFF_TYPES[item.type]}}</b-badge>
        </a>
      </template>
      <template #cell(meeting)="{item}">
        <span v-if="item.meeting_time">
          {{item.meeting_time | formatTime}}
        </span>
        <span v-else>
          -
        </span>
      </template>
      <template #cell(start)="{item}">
        <span v-if="item.start_time">
          {{item.start_time | formatTime}}
        </span>
        <span v-else>
          -
        </span>
        <span v-if="item.start_location">
          @ {{item.start_location.name}}
        </span>
      </template>
      <template #cell(end)="{item}">
        <span v-if="item.end_time">
          {{item.end_time | formatTime}}
        </span>
        <span v-else>
          -
        </span>
        <span v-if="item.end_location">
          @ {{item.end_location.name}}
        </span>
      </template>
    </b-table>
  </div>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-property-decorator'
import {namespace} from "vuex-class"
import {Tour} from "@eguide/api";

const tour = namespace('tour')
@Component
export default class TourTeam extends Vue{
  @tour.Getter tour

  get tableItems(){
    if(!this?.tour?.team) return []
    const items = []
    for(const tour of this.tour.team as Array<Tour>){
      for(const staff of tour.staff){
        if(!staff.staff) continue
        items.push({...tour, ...staff})
      }
    }
    return items
  }

  get tableFields(){
    return [
      {key: 'tour', label: 'Tour'},
      //{key: 'code', label: 'Code'},
      {key: 'staff', label: 'Staff'},
      {key: 'meeting', label: 'Meeting'},
      {key: 'start', label: 'Start'},
      {key: 'end', label: 'End'},
    ]
  }
}
</script>
