import Vue from 'vue'
import Vuex, {createLogger, StoreOptions} from 'vuex'
import { RootState } from './types'
import { tours } from './tours'
import { tour } from './tour'
import { user } from './user'
import notifications from '../../misc/store/notifications'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.1',
    online: navigator.onLine,
    updateAvailable: false
  },
  modules: {
    user,
    notifications,
    tours,
    tour,
  },
  getters: {
    isDev(state: RootState) {
      return process.env.NODE_ENV !== 'production'
    },
    isDebug(state: RootState) {
      return process.env.NODE_ENV !== 'production' || localStorage.eguideDebug
    },
  },
  mutations: {
    setOnline(state: RootState, value: boolean){
      state.online = value
    },
    setUpdateAvailable(state: RootState, value: boolean){
      state.updateAvailable = value
    }
  },
  plugins: [
    //createLogger()
  ],
}

export default new Vuex.Store<RootState>(store)
