<template>
  <b-list-group>
    <b-list-group-item v-for="tour in tours" :key="tour.id" class="d-md-flex justify-content-between align-items-center">
      <b-link :to="{name: 'tour', params: {share_id: tour.share_id, staff_id: tour._meta.staff_share_id}}" class="d-sm-block d-xs-block">
        {{tour.date}} &mdash; {{tour.name}} <span v-if="tour.tourNr">({{tour.tourNr}})</span>
      </b-link>
      <span class="badge badge-info p-2">{{tour.referenceNumber}}</span>
    </b-list-group-item>
  </b-list-group>
</template>

<script lang="ts">
  import {Component, Vue, Prop} from 'vue-property-decorator'
  import {Tour} from '@eguide/api'
  import {TourItem} from "@/apps/guide/store/tours/types";
  @Component
  export default class TourList extends Vue {
    @Prop({type: Array, required: true}) tours!: Array<TourItem>
  }
</script>
