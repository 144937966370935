import _ from 'lodash'
import { MutationTree } from 'vuex'
import { ToursState, TourItem } from './types'
import {mergeObjectRecursive} from "@/apps/guide/store/tours/util";



export const mutations: MutationTree<ToursState> = {
  setTours(state, value: TourItem[]) {
    state.list = value
  },
  addTour(state, value: TourItem) {
    state.loading = false
    state.list.push(value)

  },
  replaceTour(state, value: TourItem) {
    const index = state.list.findIndex((t) => t.id == value.id)
    state.list.splice(index, 1, value)
  },
  removeTour(state, value: TourItem) {
    const index = state.list.findIndex((t) => t.id == value.id)
    if(index < 0) return
    state.list.splice(index, 1)
  },
  setLoading(state, value: boolean) {
    state.loading = value
  }
}
