import _ from "lodash";

// NOTE: use a mergeCustomizer that does not merge array as this ight lead to wrong data
//       when an array containing objects receives a new item that leads to other objects
//       changing their index in the array
function mergeCustomizer(objValue, srcValue, key, object, source, stack) {
  if (_.isArray(srcValue) && _.isArray(objValue)) {
    return srcValue
  }
}

export interface MergeObjectRecursiveOptions {
  clone: boolean
}

export function mergeObjectRecursive(target: object, value: object, opts: MergeObjectRecursiveOptions = {clone: false}){
  if(opts.clone) {
    target = _.cloneDeep(target)
    value = _.cloneDeep(value)
  }
  _.mergeWith(target, value, mergeCustomizer)
  return target
}