<template>
  <div id="app">
    <NavbarPrimary></NavbarPrimary>
    <NavbarSecondary></NavbarSecondary>
    <Notifications></Notifications>
    <div id="page-content" class="container-fluid">
      <b-alert variant="info" show dismissible v-if="updateAvailable">
        <h2>Update available!</h2>
        <p>An update of the app is available. Please reload so the new version will be in effect!</p>
        <b-button variant="primary" @click="reloadApp">Reload</b-button>
      </b-alert>
      <div v-if="loading">
        <div class="spinner-border spinner-border-md" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        Loading...
      </div>
      <router-view v-else></router-view>
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator'
  import NavbarPrimary from './components/NavbarPrimary.vue'
  import NavbarSecondary from './components/NavbarSecondary.vue'
  import Guide from './guide'
  import Notifications from "../misc/components/Notifications.vue";
  import {namespace, State} from "vuex-class"
  const tour = namespace('tour')
  const user = namespace('user')
  const tourActivities = namespace('tourActivities')
  const lunchRequests = namespace('lunchRequests')
  const tours = namespace('tours')
  @Component({components: {NavbarPrimary, NavbarSecondary, Notifications}})
  export default class App extends Vue {
    @tour.Getter tour
    @tours.Mutation setTours
    @tourActivities.Mutation setActivities
    @user.Action restoreUser
    @State updateAvailable
    loading: boolean = true
    async mounted(){
      this.loading = false
      // run sync 3 secs after mount of app
      setTimeout(() => Guide.sync.run(), 3000)
    }
    reloadApp(){
      Guide.reloadApp()
    }
  }
</script>

<style lang="sass">
  #page-content
    padding-top: 15px
    a:not(.btn)
      color: #e4892e
      font-weight: bolder

</style>
