import _ from 'lodash'
import moment from 'moment'
import { GetterTree } from 'vuex'
import { ToursState } from './types'
import { RootState } from '../types'
import { TourItem } from './types'

export const getters: GetterTree<ToursState, RootState> = {
  tours(state: ToursState): TourItem[] {
    return state.list || []
  },
  currentTours(state: ToursState): TourItem[]{
    const today = moment()
    return _.chain(state.list)
      .filter((t) => moment(t.date).isSame(today, 'day'))
      .value()
  },
  upcomingTours(state: ToursState): TourItem[]{
    const today = moment()
    return _.chain(state.list)
      .filter((t) => moment(t.date).isAfter(today, 'day'))
      .sortBy(['date'])
      .value()
  },
  pastTours(state: ToursState): TourItem[]{
    const today = moment()
    return _.chain(state.list)
      .filter((t) => moment(t.date).isBefore(today, 'day'))
      .sortBy(['date'])
      .reverse()
      .value()
  },
  tourLoading(state: ToursState): boolean {
    return state.loading
  }
}
