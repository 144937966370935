<template>
  <div>
    <h4>Meal Request</h4>
    <b-card v-if="mealRequest" class="request-form">

      <b-row>
        <b-col md="10">
          <b-row>
            <b-col sm="3" cols="6" v-if="index < 4" v-for="(field, index) in fields" :key="field.key">
              <b-form-group :label="field.label">
                <b-input
                  :value="mealRequest.data[field.key]"
                  @change="update(field, $event)"
                  :ref="'requestInput' + index"
                  min="0"
                  :type="field.type"
                  :disabled="isDisabled"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <br/>
          <b-row>
            <b-col md="6" sm="12" v-if="index >= 4" v-for="(field, index) in fields" :key="field.key">
              <b-form-group :label="field.label">
                <b-textarea
                  rows="4"
                  @change="update(field, $event)"
                  :value="mealRequest.data[field.key]"
                  :ref="'requestInput' + index"
                  :type="field.type"
                  :disabled="isDisabled"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="2">
          <div class="text-right">
            <b-badge :variant="statusBadgeVariant" class="mr-2">
              {{mealRequest.status}}
            </b-badge>
            <b-btn-group size="sm">
              <b-button disabled v-if="isLoading" size="sm">
                <b-spinner size="sm" style="width: 1rem; height: 1rem;"></b-spinner>
              </b-button>
              <b-button size="sm" :disabled="!isSubmittable" @click="submit" variant="success">
                <fa icon="paper-plane"></fa>
                Send
              </b-button>
            </b-btn-group>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-button v-if="!mealRequest" variant="success" size="sm" @click="ensureMealRequest">
      <fa icon="plus"></fa>
      Create Meal Request
    </b-button>
  </div>
</template>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator'
  import {namespace} from 'vuex-class'
  import {SyncStatus} from '@eguide/api'
  import * as _ from 'lodash'
  import Guide from "@/apps/guide/guide";
  import {TOUR_MEAL_REQUEST_FIELDS} from "@eguide/api";

  const requests = namespace('tour/requests')

  @Component
  export default class TourMealRequest extends Vue {
    @requests.Action ensureMealRequest
    @requests.Getter mealRequest
    @requests.Action updateRequest


    update( field, value ) {
      const data: object = _.cloneDeep(this.mealRequest.data)
      switch(field.type){
        case 'number':
          const num = parseInt(value)
          data[field.key] = isNaN(num) ? 0 : num
          break
        default:
          data[field.key] = value
          break
      }
      this.updateRequest({
        id: this.mealRequest.id,
        data: {data, status: 'changed'}
      })
    }

    async submit(){
      await this.updateRequest({
        id: this.mealRequest.id,
        data: {status: 'submitted'}
      })
      Guide.sync.run()
    }

    get isSubmittable() {
      return this.mealRequest.status == 'changed'
    }

    get isDisabled() {
      return this.mealRequest.status == 'submitted' || this.mealRequest.status == 'sending' || this.mealRequest.status == 'retry'
    }

    get isSubmitted() {
      return this.mealRequest.status >= SyncStatus.Submitted
    }

    get isLoading() {
      return this.mealRequest.status == 'sending'
    }

    get fields(){
      return TOUR_MEAL_REQUEST_FIELDS
    }

    get statusBadgeVariant(){
      switch(this.mealRequest?.status){
        case 'sending': return 'secondary'
        case 'confirmed': return 'success'
        case 'changed': return 'primary'
        case 'error': return 'danger'
        default: return 'info'
      }
    }
  }
</script>