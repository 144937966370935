import _ from 'lodash'
import {difference, flatten} from '../../util'
import Api from '../../api'
import { ActionTree } from 'vuex'
import { TourState } from './types'
import { RootState } from '../types'
import Guide from "../../guide";

export const actions: ActionTree<TourState, RootState> = {

  async loadTour({dispatch, commit, rootGetters}, {tourId, staffId}){
    let tour = rootGetters['tours/tours'].find((t) => t.share_id == tourId)
    commit('setTourId', tourId)
    commit('setTourStaffId', staffId)
    if(!tour){
      try {
        commit('setLoading', true)
        tour = await dispatch('tours/loadTour', {tourId, staffId}, {root: true})
      } catch(e) {
        throw e
      } finally {
        commit('setLoading', false)
      }
    } else {
      dispatch('refreshTour', {tourId, staffId})
    }
    if(staffId){
      const tourStaff = tour.staff.find((ts) => ts.share_id == staffId)
      if(tourStaff){
        await dispatch("user/activateUser", tourStaff, {root: true})
      }
    } else {
      if(tour.staff.length > 0){
        await dispatch("user/activateUser", tour.staff[0], {root: true})
      }
    }
    //dispatch('preloadTourAttachments')
    return tour
  },


  async refreshTour({commit, getters, dispatch, rootGetters}, {staffId, tourId} = {staffId: null, tourId: null}) {
    try {
      commit('setLoading', true)
      const tour = await dispatch('tours/loadTour', {tourId: tourId || getters.tourId, staffId: staffId || getters.tour._meta.staff_share_id}, {root: true})
      if(staffId){
        const tourStaff = tour.staff.find((ts) => ts.share_id == staffId)
        if(tourStaff){
          await dispatch("user/activateUser", tourStaff, {root: true})
        }
      } else {
        const tourStaff = tour.staff.find((ts) => ts.share_id == tour._meta.staff_share_id)
        if(tourStaff){
          await dispatch("user/activateUser", tourStaff, {root: true})
        } else if(tour.staff.length > 0){
          await dispatch("user/activateUser", tour.staff[0], {root: true})
        }
      }
    } catch(e) {
      console.error( e )
      if(e.response && e.response.status == 404){
        commit('setRemoteGone', true)
      }
    } finally {
      commit('setLoading', false)
    }
  },

  async acceptTour({commit, state, getters, rootGetters, dispatch}) {
    commit('setLoading', true)
    try {
      const tourStaff = await Api.acceptTourStaff(rootGetters['tour/tourId'], rootGetters['user/staffId'])
      commit('user/setUser', tourStaff, {root:true})
      await dispatch('refreshTour')
    } finally {
      commit('setLoading', false)
    }
  },

  async tourChanged({commit, dispatch, getters}, tour){
    if(!getters.tour || getters.tour.id != tour.id){
      // while loading the tour data from the server, the current tour changed and we will ignore it
      // otherwise all fields will be displayed as changed
      return
    }
    const diff: any = difference(getters.tour, tour)
    commit('setChanges', diff)
  },

  async preloadTourAttachments({commit, getters}){
    // no need to preload documents without a service worker
    if(!Guide.serviceWorkerUrl) {
      console.log("Preload Attachment: Skipping due to no service worker present!")
      return
    }
    if(Guide.debug) {
      console.log("Preload Attachment: Skipping due to debug mode!")
      return
    }
    if(getters.tour.status == 'draft'){
      console.log("Preload Attachment: Skipping unconfirmed tour!")
      return
    }
    if(getters.remoteGone){
      console.log("Preload Attachment: Skipping tour without remote!")
      return
    }
    const cache = await window.caches.open('tour-attachments')
    getters.tourDocuments.map((attachment) => {
      new Promise(async (resolve, reject) => {
        console.log("Preload Attachment: " + attachment.url)
        commit("downloadStart", attachment.url)
        await cache.add(attachment.url)
        commit("downloadFinish", attachment.url)
      })
    })
  }
}
