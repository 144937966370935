<template>
  <div class="home">

    <section class="mb-3">
      <img class="float-md-right" height="100" alt="eguide logo" src="@tc/assets/eguide/logo/full.svg" @click="debugCounter"/>
      <h1>Home</h1>
      <p>Welcome to the eGuide, your online guide assistant tool by TimeChange.</p>
    </section>

    <section class="mb-3" v-if="currentTours.length > 0">
      <h2>Todays Tours ({{currentTours.length}})</h2>
      <TourList :tours="currentTours"/>
    </section>

    <section class="mb-3" v-if="upcomingTours.length > 0">
      <h2>Upcoming Tours ({{upcomingTours.length}})</h2>
      <TourList :tours="upcomingTours"/>
    </section>

    <section class="mb-3" v-if="pastTours.length > 0">
      <h2>Past Tours ({{pastTours.length}})</h2>
      <TourList :tours="pastTours"/>
    </section>

  </div>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator'
  import {namespace} from 'vuex-class'
  import {Tour} from "@eguide/api"
  import TourList from "@/apps/guide/components/TourList.vue";
  const tours = namespace('tours')
  @Component({
    components: {TourList}
  })
  export default class Home extends Vue {
    openDebugCounter: number = 0
    @tours.Getter tours:Tour[]
    @tours.Getter currentTours:Tour[]
    @tours.Getter upcomingTours:Tour[]
    @tours.Getter pastTours:Tour[]
    debugCounter(e){
      this.openDebugCounter += 1
      if(this.openDebugCounter >= 4){
        this.openDebugCounter = 0
        this.$router.push({name: "debug"})
      }
      window.setTimeout(() => this.openDebugCounter = 0, 5000)
    }
  }
</script>
