<template>
  <div id="navigation-primary">
    <nav class="navbar navbar-primary navbar-expand-sm bg-dark">

      <router-link to="/" class="navbar-brand">
        <img height="35" alt="Eguide logo" src="@tc/assets/eguide/logo/full_white.svg">
        <span v-b-tooltip.hover title="You are offline. New data will be synchronized when you are online again." right v-if="!online">
        <fa class="text-danger" icon="broadcast-tower"/>
      </span>
      </router-link>

      <b-navbar-toggle target="primary-nav-collapse"></b-navbar-toggle>

      <b-collapse id="primary-nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/">My Tours</b-nav-item>
          <b-nav-item to="/imprint">Imprint</b-nav-item>
          <b-nav-item to="/debug" v-if="debug">Debug</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav v-if="userLoggedIn" class="ml-auto">
          <b-nav-item >
            {{user.name}}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </nav>
  </div>
</template>

<script lang="ts">
  import Guide from '../guide'
  import { Component, Vue } from 'vue-property-decorator'
  import {namespace, State} from "vuex-class"
  const tour = namespace('tour')
  const user = namespace('user')
  @Component
  export default class NavbarPrimary extends Vue {

    @tour.Getter tour
    @tour.Getter tourLoading
    @user.Getter user
    @user.Getter userLoggedIn
    @tour.Action refreshTour

    @State online

    tourRoute(name){
      return {name: name, params: {id: this.tour.share_id}}
    }

    refresh() {
      this.refreshTour()
      Guide.sync.run()
    }

    get debug(){
      return Guide.debug
    }
  }
</script>

<style lang="sass">
  #navigation-primary
    .navbar-toggler
      border: none
    .navbar-primary
      border-bottom: solid 4px #e4892e
      .nav-item
        a
          color: white
          &.router-link-exact-active
            font-weight: bold

</style>
