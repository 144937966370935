<template>
  <div>
    <h2>Debug</h2>
    <b-button-toolbar>
      <b-button @click="handleSendState">Send state to Support</b-button>
      <b-button class="ml-2" @click="toggleDebug">
        <span v-if="debugEnabled">Disable Debug</span>
        <span v-else>Enable Debug</span>
      </b-button>
      <b-button class="ml-2" @click="clearAttachmentCache">
        Clear Attachment Cache
      </b-button>
    </b-button-toolbar>
    <br/>
    <br/>
    <b-tabs content-class="mt-3">
      <b-tab title="Tours">
        <b-list-group>
          <b-list-group-item v-for="tour in tours" :key="tour.id" class="flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">
                <b-badge>{{tour.date}}</b-badge> &mdash; <span>{{tour.name}}</span> <small>{{tour.share_id}}</small>
              </h5>
              <b-button size="sm" variant="danger" @click="handleDeleteTour(tour)">Delete Tour</b-button>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-tab>
      <b-tab title="State">
        <pre>{{state}}</pre>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator'
  import {namespace} from 'vuex-class'
  import Guide from '../guide'
  import {Tour, Activity} from "@eguide/api";
  const tours = namespace('tours')
  //const tourActivities = namespace('tour/activities')
  @Component
  export default class Debug extends Vue {
    debugEnabled: boolean = false
    @tours.Getter tours:Tour[]
    @tours.Action deleteTour

    mounted(){
      if(localStorage.getItem('eguideDebug')) this.debugEnabled = true
    }

    get state(){
      return Guide.store.state
    }

    toggleDebug(){
      if(this.debugEnabled){
        localStorage.removeItem('eguideDebug')
        this.debugEnabled = false
      } else {
        localStorage.setItem('eguideDebug', 'on')
        this.debugEnabled = true
      }
      window.location.reload()
    }

    async handleDeleteTour(tour: Tour){
      if(!confirm("Sure to delete this tour? All untransmitted forms will be removed as well.")) return
      await this.deleteTour(tour)
    }

    async handleSendState(){
      window.location.href = "mailto:cruise@time-change.com?subject=eguide state&body="+ JSON.stringify(Guide.store.state)
    }

    async clearAttachmentCache(){
      await window.caches.delete('tour-attachments')
    }


  }
</script>

