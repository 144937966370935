import { openDB, DBSchema, IDBPDatabase } from 'idb'
import {Voucher, Tour} from "@eguide/api"

export interface SyncTask {
  id: string
  task: string
  data: any
}

export interface EGuideDbSchema extends DBSchema {
  tours: {
    key: string,
    value: Tour,
    indexes: {
      byShareId: string
    }
  },
  vouchers: {
    key: string,
    value: Voucher
    indexes: {
      byTourId: string
      byStatus: number
    }
  },
  syncTasks: {
    key: string,
    value: SyncTask
  }
}

export default class Storage {

  db: IDBPDatabase<EGuideDbSchema>

  get tables(): string[] {
    return  ['tours']
  }
  async connect(){
    return this.db = await openDB<EGuideDbSchema>('eGuide.staff', 7, {
      upgrade(db, from, to, transaction) {
        const dbGeneric = db as unknown as IDBPDatabase
        console.log("Storage: upgrade from %s to %s", from, to)
        if(from < 1){
          console.log("Storage: create initial schema")
          db.createObjectStore('tours', {keyPath: 'id'})
          db.createObjectStore('vouchers', { keyPath: 'id' })
            .createIndex('byTourId', 'tourId')
        }
        if(from < 2){
          console.log("Storage: create voucher byStatus index")
          transaction.objectStore('vouchers').createIndex('byStatus', 'status')
        }
        if(from < 3){
          console.log("Storage: create lunchRequest")
          dbGeneric.createObjectStore('lunchRequests', {keyPath: 'id'})
        }
        if(from < 4){
          console.log("Storage: delete lunchRequest")
          dbGeneric.deleteObjectStore('lunchRequests')
        }

        if(from < 5){
          console.log("Storage: delete all stores")
          try {
            dbGeneric.deleteObjectStore('lunchRequests')
          } catch(e) {}
          try {
            dbGeneric.deleteObjectStore('vouchers')
          } catch(e) {}
          try {
            dbGeneric.deleteObjectStore('tours')
          } catch(e) {}
        }

        if(from < 6){
          console.log("Storage: setup nextgen")
          db.createObjectStore('tours', {keyPath: 'id'})
          transaction.objectStore('tours').createIndex('byShareId', 'share_id')
        }
        if(from < 7){
          console.log("Storage: setup sync tasks")
          db.createObjectStore('syncTasks', {keyPath: 'id'})
        }
      }
    })
  }

  async findTour(id: string): Promise<Tour>{
    if(!this.db) await this.connect()
    return this.db.getFromIndex('tours', 'byShareId', id)
  }


  async listTours(): Promise<Tour[]>{
    if(!this.db) await this.connect()
    return this.db.getAll('tours')
  }

  async persistTour(tour: Tour){
    if(!this.db) await this.connect()
    await this.db.put('tours', tour)
    console.log("Storage: persisted tour", tour.id)
  }

  async deleteTour(id: string){
    if(!this.db) await this.connect()
    await this.db.delete('tours', id)
    console.log("Storage: deleted tour", id)
  }
}

