import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { ToursState } from './types'
import { RootState } from '../types'

export const state: ToursState = {
  list: [],
  loading: false
}

const namespaced: boolean = true

export const tours: Module<ToursState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
