import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations'
import { actions } from './actions'
import { TourState } from './types';
import { RootState } from '../types';
import { requests } from './requests'
import { vouchers } from "./vouchers";
import { feedback } from './feedback'

export const state: TourState = {
  tourId: null,
  tourStaffId: null,
  loading: false,
  remoteGone: false,
  changes: null,
  downloads: {}
};

const namespaced: boolean = true;

export const tour: Module<TourState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
  modules:{ requests, feedback, vouchers }
};
