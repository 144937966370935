<template>
  <b-dropdown ref="buddyDropdown" class="buddies" dropright v-if="buddiesPresent" size="sm">
    <template slot="button-content"><fa icon="users"/></template>
    <template v-if="tour[buddyType.key] && tour[buddyType.key].length > 0" v-for="buddyType in buddyTypes">
      <b-dropdown-header>{{buddyType.label}}</b-dropdown-header>
      <a @click="close()" class="dropdown-item" v-if="person" v-for="person in tour[buddyType.key]" :href="'tel:' + person.phone"><fa icon="phone"/>
        {{person.name}}</a>
    </template>
  </b-dropdown>
</template>

<script lang="ts">
  import {Vue, Component} from "vue-property-decorator";
  import {Tour} from "@eguide/api";
  import {namespace} from "vuex-class";
  import * as _ from 'lodash'

  const tour = namespace('tour')

  @Component
  export default class BuddyList extends Vue {
    @tour.Getter tour:Tour
    name: "BuddyList"

    get buddiesPresent() {
      return !_.every( _.map( this.buddyTypes, (type) => _.isEmpty( this.tour[type.key] ) ) )
    }

    close() {
      (<any>this.$refs.buddyDropdown).hide()
    }

    buddyTypes = [
      {key:'guideBuddies', label:'Your team for tour'},
      {key:'boatBuddies', label:'Your team for boat trip'},
      {key:'lunchBuddies', label:'Your team for lunch'}
    ]
  }
</script>

<style lang="sass">
  .buddies
    .dropdown-menu
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15) !important
</style>
