<template>
  <div>
    <TourInfo :tour="tour" :tour-staff="tourStaff"/>
    <TourDescription :tour="tour" :tour-staff="tourStaff"/>
  </div>
</template>

<script lang="ts">
  import {Vue, Component} from 'vue-property-decorator'
  import {namespace} from 'vuex-class'
  import {Tour, TourStaff} from "@eguide/api";
  const tour = namespace('tour')
  const user = namespace('user')
  @Component
  export default class TourOverview extends Vue {
    @tour.Getter tour: Tour
    @user.Getter tourStaff: TourStaff
  }
</script>
