<template>
  <b-modal
    modal-class="modal-fullscreen sign-pad"
    id="signature-modal"
    ref="signPadModal"
    title="Signature"
    :hide-footer="true">
    <VueSignaturePad
      saveType="image/svg+xml"
      class="signature-input"
      ref="signPad"/>
    <div class="please-turn">
      If you need more space please turn your phone.
      <br/>
    </div>
    <br/>
    <b-row>
      <b-col cols="6">
        <b-button @click="$refs.signPad.clearSignature()">Clear</b-button>
      </b-col>
      <b-col cols="6" class="text-right">
        <b-button variant="primary" @click="signConfirmation">Sign</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script lang="ts">
  import VueSignaturePad from 'vue-signature-pad'
  import {Component, Prop, Vue} from 'vue-property-decorator'
  import {Voucher} from "@eguide/api";

  @Component( {
    components: {
      VueSignaturePad
    }
  })
  export default class SignModal extends Vue {
    @Prop() voucher: Voucher
    @Prop() main: Boolean

    mounted() {
      //https://github.com/neighborhood999/vue-signature-pad/issues/62
      (<any>this.$refs.signPadModal).$on("shown", () => {
        this.resetPad()
        this.resizePad()
      })
    }

    resetPad(){
      (<any>this.$refs.signPad).clearSignature()
    }

    resizePad(){
      (<any>this.$refs.signPad).resizeCanvas()
    }

    signConfirmation() {
      const { isEmpty, data } = (<any>this.$refs.signPad).saveSignature()
      if(!isEmpty) {
        this.$emit('signed', data)
      }
      (<any>this.$refs.signPadModal).hide()
    }
  }
</script>

<style lang="sass">

  .please-turn
    display: none

  .sign-pad
    .signature-input
      border-radius: 5px
      border: 1px lightgrey solid
      background-color: #eee
      height: 45vh !important

  @media (orientation: portrait)
    .sign-pad
      //.modal
        overflow: visible
        top: 50%
        left: 50%
        width: 100vh
        height: 100vw
        -webkit-transform: translate(-50%, -50%) rotate(90deg)
        -moz-transform: translate(-50%, -50%) rotate(90deg)
        -o-transform: translate(-50%, -50%) rotate(90deg)
        -ms-transform: translate(-50%, -50%) rotate(90deg)
        transform: translate(-50%, -50%) rotate(90deg)

      //.modal-content
        height: 100vw !important
        width: 100vh

      .please-turn
        display: block

      .signature-input
        height: 25vh !important

</style>
