import Guide from '../../guide'
import { ActionTree } from 'vuex'
import { UserState } from './types'
import { RootState } from '../types'

export const actions: ActionTree<UserState, RootState> = {
  async activateUser({dispatch, commit, getters}, user) {
    if(!user){
      return
    }
    if(!getters.user || (getters.user && getters.user.mail != user.mail)){
      await dispatch('changeUser', user)
    }
  },
  async changeUser({commit}, user){
    localStorage.eguideUser = JSON.stringify(user)
    commit("setUser", user)
    // reconfigure rollbar payload
    /*Guide.rollbar.configure({
      payload: {
        person: {
          id:  user.mail,
          username: user.name,
          email:  user.mail
        }
      }
    })*/
  },
  async restoreUser({commit}){
    if(!localStorage.eguideUser) return
    let user = null
    try {
      user = JSON.parse(localStorage.eguideUser)
    } catch(e) {
      console.log("UserStore: Error parsing persisted user", e)
    }
    console.log("UserStore: Restoring from localStorage", user)
    commit('setUser', user)
  }
}
