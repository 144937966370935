import { MutationTree } from 'vuex';
import { FeedbackState } from './types';

export const mutations: MutationTree<FeedbackState> = {
  setText(state, payload) {
    state.text = payload
  },
  setTransmitting(state, payload) {
    state.transmitting = payload
  },
  setTransmitted(state, payload) {
    state.transmitted = payload
  },
  setTransmittingError(state, payload) {
    state.transmittingError = payload
  }
};
