import '../misc/vue_class_hooks'
import '../misc/ui'
import Vue from 'vue'
import App from './App.vue'
import Guide from './guide'
import FloatLabel from "vue-float-label/components/FloatLabel.vue"
import GoogleMaps from "../misc/plugins/google_maps";
import {currentYear, formatCurrency, formatDate, formatDatetime, formatTime} from "@hc/graphql";
import {constants} from '@eguide/api'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

Vue.filter('formatDate', formatDate)
Vue.filter('formatDatetime', formatDatetime)
Vue.filter('formatTime', formatTime)
Vue.filter('formatCurrency', formatCurrency)
Vue.filter('currentYear', currentYear)

Vue.component("FloatLabel", FloatLabel)
Vue.component("JsonPretty", VueJsonPretty)

Vue.use(GoogleMaps)
Vue.use({
  install() {
    Vue.prototype.$const = constants
    Vue.prototype.$dev  = process.env.NODE_ENV != 'production'
  }
})

Guide.storage.connect().then(async () => {
  const tours = await Guide.storage.listTours()
  // reset voucher sending status to retry, so sync will run again
  tours.forEach((tour) => {
    if(tour.voucher_forms){
      tour.voucher_forms.forEach((v) => {
        if(v.status == 'sending') {
          v.status = "retry"
        }
      })
    }
    if(tour.request_forms){
      tour.request_forms.forEach((v) => {
        if(v.status == 'sending') {
          v.status = "retry"
        }
      })
    }
  })
  Guide.store.commit('tours/setTours', tours)
  const vue = new Vue({
    router: Guide.router,
    store: Guide.store,
    render: (h) => h(App),
  }).$mount('#app')
  const loader = document.getElementById('app-loader')
  if(loader) loader.remove()
})

window['eGuide'] = Guide;