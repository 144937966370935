import {ActionTree} from 'vuex';
import {RequestState} from './types';
import {RootState} from '../../types';
import {SyncStatus} from "@eguide/api";
import {Tour} from "@eguide/api";

export const actions: ActionTree<RequestState, RootState> = {
  async updateRequest({dispatch, rootGetters}, {id, data}) {
    const tour = rootGetters['tour/tour']
    await dispatch('tours/updateTourRequest', {id: tour.share_id, requestId: id, data}, {root: true})
  },
  async ensureMealRequest({dispatch, getters, rootGetters}) {
    if(!getters.mealRequest) {
      const tour: Tour = rootGetters['tour/tour']
      console.log("ADD REQUEST", tour.id)
      const data = {
        type: "meal_request",
        status: "draft",
        data: {
          vegetarian: 0,
          vegan: 0,
          lactose: 0,
          gluten: 0,
          other: '',
          other2: '',
        }
      }
      await dispatch('tours/insertTourRequest', {id: tour.share_id, data}, {root: true})
    }
  },
};
