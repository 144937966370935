import {ActionTree} from "vuex"
import {RootState} from "../../types"
import {VouchersState} from "./types"
import Guide from "@/apps/guide/guide";
import _ from "lodash";


export const actions: ActionTree<VouchersState, RootState> = {
  updateVoucher: async ({state,getters, rootGetters, dispatch}, {id, data}) => {
    const tour = rootGetters['tour/tour']
    await dispatch('tours/updateTourVoucher', {id: tour.share_id, voucherId: id, data}, {root: true})
  },
  submitVoucher: async ({commit, state, dispatch, getters, rootGetters}, voucher) => {
    const tour = rootGetters['tour/tour']
    await dispatch('updateVoucher',{id: voucher.id, data: {status: 'submitted'}})
  },
  sendVoucher: async ({commit, state, dispatch, getters, rootGetters}, voucher) => {
    const tourId = rootGetters['tour/tourId']
    const staffId = rootGetters['user/staffId']
    try {
      await dispatch('updateVoucher', {id: voucher.id, data: {status: 'sending'}})
      const tourData = await Guide.api.submitTourVoucher(tourId, staffId, voucher)
      await dispatch('updateVoucher', {id: voucher.id, data: {status: 'confirmed'}})
    } catch(e) {
      await dispatch('updateVoucher', {id: voucher.id, data: {status: 'error'}})
      throw e
    }
  },
}