<template>
  <div>
    <template v-if="loading">
      <div class="spinner-border spinner-border-md" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <p>Loading tour...</p>
    </template>
    <template v-else-if="tourNotFound">
      <b-alert variant="danger" show>
        <h2>Tour not found!</h2>
        <p>Maybe the link you clicked expired or there is a typo in the tour id.</p>
      </b-alert>
    </template>
    <template v-else-if="tour">
      <tour-header />
      <hr/>
      <tour-notifications />
      <router-view></router-view>
      <div class="mt-2">
        <div class="text-center">{{tour.ship_call.cruise.code}} / {{tour.ship_call.cruise.reference}}</div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
  import {namespace} from 'vuex-class'
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import Guide from '../guide'
  import {TourItem} from "@/apps/guide/store/tours/types";
  import BuddyList from "@/apps/guide/components/BuddyList.vue";
  import TourHeader from "@/apps/guide/components/TourHeader.vue";
  import TourNotifications from "@/apps/guide/components/TourNotifications.vue";

  const user = namespace('user')
  const tours = namespace('tours')
  const tour = namespace('tour')
  const vouchers = namespace('tour/vouchers')
  @Component({
    components: {TourNotifications, TourHeader, BuddyList}
  })
  export default class TourComponent extends Vue {

    refreshInterval: any = null
    tourNotFound: boolean = false
    loading: boolean = true

    @Prop({type: String, required: true}) share_id!: string
    @Prop({type: String}) staff_id: string

    @tour.Getter tourLoading

    @tour.Getter tour: TourItem
    @tours.Getter tours
    @tour.Mutation setTourId
    @tour.Mutation setLoading
    @tour.Mutation clearTourState
    @vouchers.Mutation clearVoucherState

    @user.Getter tourStaff
    @tour.Action loadTour

    @tour.Action refreshTour


    async mounted() {
      this.loading = true
      console.log("Tour: Load tour=%s staff=%s", this.share_id, this.staff_id)
      try {
        await this.loadTour({tourId: this.share_id, staffId: this.staff_id})
      } catch(e) {
        this.tourNotFound = true
        return
      } finally {
        this.loading = false
      }
      console.log("Tour: Loaded tour=%s staff=%s", this.tour.share_id, this.tour._meta.staff_share_id)
      this.refreshInterval = window.setInterval(() => {
        this.refreshTour()
      }, 1000 * 60 * 10)
      this.loading = false
    }


    beforeRouteLeave (to, from, next) {
      if(this.refreshInterval) window.clearInterval(this.refreshInterval)
      this.clearTourState()
      this.clearVoucherState()
      next()
    }
  }
</script>
