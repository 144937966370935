import { GetterTree } from 'vuex';
import { RequestState } from './types';
import { RootState } from '../../types';
import {Tour} from "@eguide/api";

export const getters: GetterTree<RequestState, RootState> = {
  requests(state, getters, rootState, rootGetters) {
    const tour:Tour = rootGetters["tour/tour"]
    return tour.request_forms || []
  },
  mealRequest(state, getters, rootState, rootGetters) {
    return getters.requests.find((r) => r.type == 'meal_request')
  },
}
