<template>
  <div id="navigation-secondary" class="sticky-top">
    <nav class="navbar navbar-secondary navbar-expand bg-light shadow-sm" v-if="tour">

      <b-navbar-nav>
        <b-nav-item exact :to="tourRoute('tour')">Description</b-nav-item>
        <template v-if="tourStaff && tourStaff.confirmed_at && tourStaff.accepted_at && !tourStaff.canceled_at">
          <b-nav-item :to="tourRoute('tourVouchers')" v-if="vouchers.length >  0">Vouchers</b-nav-item>
          <b-nav-item v-if="tour.fnbs.length > 0" :to="tourRoute('tourMealRequest')">Meal Request</b-nav-item>
          <b-nav-item :to="tourRoute('tourDocuments')">Documents</b-nav-item>
          <b-nav-item :to="tourRoute('tourTeam')">Team</b-nav-item>
          <b-nav-item :to="tourRoute('tourItinerary')">Itinerary</b-nav-item>
          <b-nav-item :to="tourRoute('tourFeedback')">Feedback</b-nav-item>
        </template>
        <b-nav-item :to="tourRoute('tourDebug')" v-if="debug">Debug</b-nav-item>
        <b-nav-item @click="refresh()" v-if="!tourLoading">
          <fa icon="sync-alt"/>
        </b-nav-item>
        <b-nav-item v-else>
          <b-spinner label="Loading" small></b-spinner>
        </b-nav-item>
      </b-navbar-nav>
    </nav>
  </div>
</template>

<script lang="ts">
  import Guide from '../guide'
  import { Component, Vue } from 'vue-property-decorator'
  import {namespace, State} from "vuex-class"
  const tour = namespace('tour')
  const user = namespace('user')
  const vouchers = namespace('tour/vouchers')
  @Component
  export default class NavbarSecondary extends Vue {

    @user.Getter tourStaff
    @tour.Getter tour
    @tour.Getter tourLoading
    @tour.Action refreshTour
    @vouchers.Getter vouchers

    @State online

    tourRoute(name){
      return {name: name, params: {id: this.tour.share_id, staff_id:String(this.tourStaff?.share_id )}}
    }

    refresh() {
      this.refreshTour()
      Guide.sync.run()
    }

    get debug(){
      return Guide.debug
    }
  }
</script>

<style lang="sass">
  #navigation-secondary
    //a
      font-weight: bold
      color: #2c3e50
      &.router-link-exact-active
        color: #42b983
    .navbar-toggler
      border: none
    .navbar-secondary
      border-bottom: 1px solid rgba(0, 0, 0, 0.15)
      overflow-y: scroll
      .nav-item
        a
          padding-top: 2px
          padding-bottom: 2px
          color: black
          font-weight: bold
          white-space: pre
          &.router-link-active
            color: #e4892e !important
</style>
