import Api from '../../api'
import { ActionTree } from 'vuex'
import {TourItem, ToursState} from './types'
import { RootState } from '../types'
import {Tour} from "@eguide/api";
import {mergeObjectRecursive} from "@/apps/guide/store/tours/util";
import _ from 'lodash'

export const actions: ActionTree<ToursState, RootState> = {

  async loadTour({commit, state, getters, dispatch, rootGetters}, {tourId, staffId}): Promise<Tour> {
    commit('setLoading', true)
    try {
      const tourData = await Api.getTourData(tourId)
      const index = getters.tours.findIndex((tour) => tour.share_id == tourId)
      const tourStaff = tourData.staff.find((ts) => ts.share_id == staffId)
      const tour = {
        ...tourData,
        _meta: {
          staff_share_id: tourStaff?.share_id,
          staff_id: tourStaff?.staff?.id
        }
      }
      if(index >= 0) {
        await dispatch('mergeTour', tour)
      } else {
        commit('addTour', tour)
      }
      return tour
    } catch( e ){
      throw e
    } finally {
      commit('setLoading', false)
    }
  },

  async deleteTour({commit, state}, tour){
    commit('removeTour', tour)
  },

  async mergeTour({commit, state, dispatch, getters}, tourInput){
    const tour = getters.tours.find((t) => t.id == tourInput.id)
    if(!tour) throw new Error('Tour not found: ' + tourInput.id)
    const newTour = mergeObjectRecursive(tour, tourInput, {clone: true}) as TourItem
    // wee need to check all vouchers for local changes and copy them over if there is no newer version from server
    for(const newVoucher of newTour.voucher_forms){
      const oldVoucher = tour.voucher_forms.find((v) => v.id == newVoucher.id)
      if(!oldVoucher) continue
      switch(oldVoucher.status){
        case 'changed':
          // voucher has been changed locally, so we need to rebase the local changes (if more recent) to the new attendances
          // set status to changed
          newVoucher.status = 'changed'
          // keep signatures if present in old voucher
          if(oldVoucher.staff_signature) newVoucher.staff_signature = oldVoucher.staff_signature
          if(oldVoucher.counter_signature) newVoucher.counter_signature = oldVoucher.counter_signature
          // sync activities
          for(const newActivity of newVoucher.activities){
            const oldActivity = oldVoucher.activities.find((a) => a.id == newActivity.id)
            if(!oldActivity) continue
            for(const newAttendance of newActivity.attendances){
              const oldAttendance = oldActivity.attendances.find((a) => a.id == newAttendance.id)
              if(!oldAttendance) continue
              if(newAttendance.updated_at <= oldAttendance.updated_at){
                newAttendance.quantity = oldAttendance.quantity
              }
            }
          }
          break
        case 'submitted':
        case 'sending':
        case 'retry':
          // merge over whole voucher because it is currently in transmission
          _.merge(newVoucher, oldVoucher)
          break
      }
    }
    // wee need to check all requests for local changes and copy them over if there is no newer version from server
    for(const newRequest of newTour.request_forms){
      const oldRequest = tour.request_forms.find((v) => v.id == newRequest.id)
      if(!oldRequest) continue
      switch(oldRequest.status){
        case 'changed':
          // voucher has been changed locally, so we need to rebase the local changes (if more recent) to the new attendances
          newRequest.status = 'changed'
          newRequest.data = oldRequest.data
          break
        case 'submitted':
        case 'sending':
        case 'retry':
          // merge over whole voucher because it is currently in transmission
          _.merge(newRequest, oldRequest)
          break
      }
    }
    await dispatch('tour/tourChanged', newTour, {root: true})
    commit('replaceTour', newTour)
  },
  async updateTour({commit, state, dispatch, getters}, tourInput){
    const tour = getters.tours.find((t) => t.id == tourInput.id)
    if(!tour) throw new Error('Tour not found: ' + tourInput.id)
    const newTour = mergeObjectRecursive(tour, tourInput, {clone: true}) as TourItem
    await dispatch('tour/tourChanged', newTour, {root: true})
    commit('replaceTour', newTour)
  },
  async updateTourVoucher({commit, state, dispatch, getters}, {id, voucherId, data}){
    const tour = state.list.find((t) => t.share_id == id)
    if(!tour) return false
    const vouchers = _.cloneDeep(tour.voucher_forms)
    const voucher = vouchers.find((v) => v.id == voucherId)
    if(!voucher) return false
    const voucherIndex = vouchers.indexOf(voucher)
    _.merge(vouchers[voucherIndex], data)
    await dispatch('updateTour', {id: tour.id, voucher_forms: vouchers})
  },
  async insertTourRequest({commit, state, dispatch, getters}, {id, data}){
    const tour = state.list.find((t) => t.share_id == id)
    if(!tour) return false
    const requests = _.cloneDeep(tour.request_forms)
    requests.push(data)
    await dispatch('updateTour', {id: tour.id, request_forms: requests})
  },
  async updateTourRequest({commit, state, dispatch, getters}, {id, requestId, data}){
    const tour = state.list.find((t) => t.share_id == id)
    if(!tour) return false
    const requests = _.cloneDeep(tour.request_forms)
    const request = requests.find((v) => v.id == requestId)
    if(!request) return false
    const voucherIndex = requests.indexOf(request)
    _.merge(requests[voucherIndex], data)
    await dispatch('updateTour', {id: tour.id, request_forms: requests})
  },
  async preloadTourAttachments(store, tour){
    const urls = tour.attachments.map((a) => a.url)
    const cache = await window.caches.open('tour-attachments')
    await cache.addAll(urls)
  }

}
