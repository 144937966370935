import Guide from './guide'
import {TourItem} from "@/apps/guide/store/tours/types";

const Sync = {
  running: false,
  get tours(): TourItem[]{
    return Guide.store.getters["tours/tours"]
  },
  get syncVouchers(){
    const items = []
    for(const tour of this.tours){
      if(tour.voucher_forms){
        for(const voucher of tour.voucher_forms){
          if(voucher.status == 'submitted' || voucher.status == 'retry'){
            items.push({
              type: 'voucher',
              tour,
              voucher,
            })
          }
        }
      }
    }
    return items
  },
  get syncRequests(){
    const items = []
    for(const tour of this.tours){
      if(tour.request_forms){
        for(const request of tour.request_forms){
          if(request.status == 'submitted' || request.status == 'retry'){
            items.push({
              type: 'request',
              tour,
              request,
            })
          }
        }
      }
    }
    return items
  },
  get syncItems(){
    return [...this.syncVouchers, ...this.syncRequests]
  },
  async sync(){
    if(this.syncVouchers.length > 0){
      console.log("Sync: Synchronizing %s vouchers.", this.syncVouchers.length)
      for(const item of this.syncVouchers){
        console.log("Sync: Item tour=%s staff=%s voucher=%s",item.tour.share_id, item.tour._meta.staff_share_id, item.voucher.id)
        await Guide.store.dispatch('tours/updateTourVoucher', {
          id: item.tour.share_id,
          voucherId: item.voucher.id,
          data: {status: 'sending'}
        })
        try {
          const tour = await Guide.api.submitTourVoucher(item.tour.share_id, item.tour._meta.staff_share_id, item.voucher)
          await Guide.store.dispatch('tours/updateTour', tour)
        } catch(e) {
          console.log("Sync: Error %s", e.message)
          await Guide.store.dispatch('tours/updateTourVoucher', {
            id: item.tour.share_id,
            voucherId: item.voucher.id,
            data: {status: e.response?.status == 404 ? 'error' : 'retry'}
          })
          throw e
        }
      }
    }
    if(this.syncRequests.length > 0){
      console.log("Sync: Synchronizing %s requests.", this.syncRequests.length)
      for(const item of this.syncRequests) {
        console.log("Sync: Item tour=%s staff=%s request=%s", item.tour.share_id, item.tour._meta.staff_share_id, item.request.id)
        console.log(item.request)
        await Guide.store.dispatch('tours/updateTourRequest', {
          id: item.tour.share_id,
          requestId: item.request.id,
          data: {status: 'sending'}
        })
        try {
          const tour = await Guide.api.submitTourRequest(item.tour.share_id, item.tour._meta.staff_share_id, item.request)
          await Guide.store.dispatch('tours/updateTour', tour)
        } catch(e) {
          console.log("Sync: Error %s", e.message)
          await Guide.store.dispatch('tours/updateTourRequest', {
            id: item.tour.share_id,
            requestId: item.request.id,
            data: {status: e.response?.status == 404 ? 'error' : 'retry'}
          })
          throw e
        }
      }
    }


  },
  async run(retryCount: number = 3){
    // pre checks
    if(this.running){
      console.log("Sync: Already running")
      return
    }
    if(!navigator.onLine){
      console.log("Sync: Offline, will not start.")
      return
    }

    if(this.syncItems.length == 0) {
      console.log("Sync: Nothing to sync")
      this.running = false
      return
    }

    console.log("Sync: Starting with retry count = ", retryCount)
    // initialize
    this.running = true

    try{
      await this.sync()
    } catch(e){
      console.error(e)
      if(retryCount > 0){
        console.log("Sync: Error, retrying in 10secs")
        window.setTimeout(()=> this.run(retryCount - 1), 10000)
      } else {
        console.log("Sync: To many failures, giving up.")
      }
    } finally {
      this.running = false
    }

  }
}


export default Sync
