<template>
  <div>
    <b-list-group class="shadow-sm" style="margin-bottom: 0.75rem;">
      <b-list-group-item v-for="attachment in tourDocuments" :key="attachment.id">
        <div class="d-md-flex justify-content-between align-items-center">
          <b-link @click.prevent.stop="fetchAttachment(attachment)">{{attachment.name}}</b-link>
          <b-spinner class="text-success ml-2" v-if="downloadState[attachment.url] === 'loading'" small></b-spinner>
          <fa icon="check" v-else-if="downloadState[attachment.url] === 'finished'" />
          <fa icon="question" v-else />
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script lang="ts">
  import {Vue, Component} from 'vue-property-decorator'
  import {namespace} from "vuex-class"
  import fileSaver from 'file-saver'
  import {TourStaffAttachment} from "@eguide/api"
  import * as _ from 'lodash'

  const tour = namespace('tour')
  const tours = namespace('tours')
  const user = namespace('user')

  @Component
  export default class TourDocuments extends Vue{
    @tour.Getter tour
    @tour.Getter tourDocuments
    @tour.Getter downloads
    @tour.Getter downloadState
    @tour.Mutation downloadStart
    @tour.Mutation downloadFinish
    @user.Getter tourStaff

    async fetchAttachment(attachment) {
      this.downloadStart(attachment.url)
      const response = await fetch(attachment.url)
      const blob = await response.blob()
      await fileSaver.saveAs( blob , attachment.name )
      this.downloadFinish(attachment.url)
    }
  }
</script>
