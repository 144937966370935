<template>
  <div>
    <div class="d-inline h4">
      <b-badge class="float-right">{{tour.date}}</b-badge> <b-badge variant="info">{{tour.code}}</b-badge> {{tour.name}} <b-badge variant="info" v-if="tour.tour_nr">{{tour.tour_nr}}</b-badge>
    </div>
    <hr/>
    <div class="d-md-flex">
      <div v-if="tour.emergency_contact" class="mr-3">
        <strong>Emergency Contact: </strong>
        <a class="contact-link" :href="'tel:' + tour.emergency_contact.phone">
          <fa icon="phone"/> {{tour.emergency_contact.first_name + ' ' + tour.emergency_contact.last_name}}
        </a>
      </div>
      <div v-if="tour.ship_call.cruise.managers.length > 0" class="mr-3">
        <strong>Cruise Manager: </strong>
        <template v-for="(manager, index) of tour.ship_call.cruise.managers">
          <a class="contact-link" :href="'tel:' + manager.contact.phone" :key="manager.id"><fa icon="phone"/> {{manager.contact.name}}</a>
          <span v-if="index < tour.ship_call.cruise.managers.length - 1">, </span>
        </template>
      </div>
    </div>
    <!--<div v-if="otherStaff.length > 0">
      <strong>Staff: </strong>
      <template v-for="(tourStaff, index) of otherStaff">
        <a class="contact-link" :href="'tel:' + tourStaff.staff.contact.phone" :key="tourStaff.id" ><fa icon="phone"/> {{tourStaff.staff.contact.name}} ({{$const.STAFF_TYPES[tourStaff.type]}})</a>
        <span v-if="index < otherStaff.length - 1">, </span>
      </template>
    </div>-->
    <!--<BuddyList></BuddyList>-->
  </div>
</template>

<script lang="ts">
import {namespace} from 'vuex-class'
import { Vue, Component, Prop } from 'vue-property-decorator'
import {TourItem} from "@/apps/guide/store/tours/types";
import BuddyList from "@/apps/guide/components/BuddyList.vue";
const tour = namespace('tour')
const user = namespace('user')
@Component({
  components: {BuddyList}
})
export default class TourComponent extends Vue {
  @tour.Getter
  tour: TourItem

  @user.Getter
  staffId: string

  get otherStaff(){
    return this.tour.staff.filter((s) => {
      if(!s.staff) return false
      if(s.share_id == this.staffId) return false
      return true
    })
  }
}
</script>

<style lang="sass" scoped>
  .contact-link
    white-space: pre-wrap
</style>