import {GetterTree} from "vuex"
import {RootState} from "../../types"
import {VouchersState} from "./types"

export const getters: GetterTree<VouchersState, RootState> = {
  vouchers: (state, getters, rootState, rootGetters) => rootGetters['tour/tourVoucherForms'],
  activeVoucher: (state, getters) => getters.vouchers.find((v) => v.id == state.activeVoucherId),
  mainVoucher: (state, getters, rootState, rootGetters) => {
    return getters.vouchers.find((v) => v.staff_id == rootGetters['user/staffId']) || getters.vouchers[0]
  },
}
