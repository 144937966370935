import { MutationTree } from 'vuex';
import { TourState } from './types';

export const mutations: MutationTree<TourState> = {
  setTourId(state, value) {
    state.tourId = value
  },
  setTourStaffId(state, value) {
    state.tourStaffId = value
  },
  setLoading(state, value: boolean) {
    state.loading = value
  },
  setRemoteGone(state, value: boolean) {
    state.remoteGone = value
  },
  setChanges(state, diff){
    state.changes = diff
  },
  clearChangedFields(state){
    state.changes = null
  },
  clearTourState(state: TourState){
    state.tourId = null
    state.changes = null
    state.remoteGone = false
  },
  downloadStart(state, url) {
    const data = {}
    data[url] = "loading"
    state.downloads = {...state.downloads, ...data}
  },
  downloadFinish(state, url) {
    const data = {}
    data[url] = "finished"
    state.downloads = {...state.downloads, ...data}
  },
};
