import { GetterTree } from 'vuex'
import { UserState } from './types'
import { RootState } from '../types'
import {Contact} from "@eguide/api";

export const getters: GetterTree<UserState, RootState> = {
  user(state: UserState): Contact {
    if( state.user )
      return state.user?.staff?.contact
  },
  tourStaff(state: UserState) {
    return state.user
  },
  staffId(state: UserState) {
    return state?.user?.share_id
  },
  userLoggedIn(state: UserState): boolean{
    return !!state.user && !!state.user.staff
  },
}
