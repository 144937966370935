<template>
  <div>
    <div v-if="loading">
      <b-spinner small  /> Resolving short link...
    </div>

    <b-alert v-else-if="url" show variant="success" >
      <b-spinner small  /> Redirecting to {{url}}
    </b-alert>

    <b-alert v-else-if="error" show variant="danger" >
      {{error}}
    </b-alert>

  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'

import Api from '../api'

@Component
export default class ShortLink extends Vue {

  loading: boolean = null
  error:string = null
  url: string = null

  async created(){
    this.loading = true
    try {
      this.url = await Api.expandShortLink(window.location.toString())
      this.$nextTick(() => {
        window.location.href = this.url
      })
    } catch(e){
      this.error = 'Error resolviong short link!'
    } finally {
      this.loading = false
    }
  }
}

</script>