import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Tour from './views/Tour.vue'
import TourOverview from './views/TourOverview.vue'
import TourDocuments from './views/TourDocuments.vue'
import TourDebug from './views/TourDebug.vue'
import Imprint from './views/Imprint.vue'
import Debug from './views/Debug.vue'
import ShortLink from './views/ShortLink.vue'
import TourFeedback from "./views/TourFeedback.vue"
import TourVouchers from "./views/TourVouchers.vue"
import TourItinerary from "./views/TourItinerary.vue"
import TourTeam from "./views/TourTeam.vue"
import TourMealRequest from "@/apps/guide/views/TourMealRequest.vue";

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/tour/:share_id/:staff_id?',
      component: Tour,
      props: true,
      children: [
        {
          path: '',
          name: 'tour',
          component: TourOverview,
          props: true
        },
        {
          path: 'vouchers',
          name: 'tourVouchers',
          component: TourVouchers,
          props: true
        },
        {
          path: 'meal-request',
          name: 'tourMealRequest',
          component: TourMealRequest,
          props: true
        },
        {
          path: 'documents',
          name: 'tourDocuments',
          component: TourDocuments,
          props: true
        },
        {
          path: 'feedback',
          name: 'tourFeedback',
          component: TourFeedback,
          props: true
        },
        {
          path: 'itinerary',
          name: 'tourItinerary',
          component: TourItinerary,
          props: true
        },
        {
          path: 'team',
          name: 'tourTeam',
          component: TourTeam,
          props: true
        },
        {
          path: 'debug',
          name: 'tourDebug',
          component: TourDebug,
          props: true
        },
      ]
    },
    {
      path: '/imprint',
      name: 'imprint',
      component: Imprint
    },
    {
      path: '/debug',
      name: 'debug',
      component: Debug
    },
    {
      path: '/:code',
      name: 'shortLink',
      component: ShortLink
    }
  ],
})
