import {ActionTree} from 'vuex';
import {RootState} from '../../types';
import {FeedbackState} from './types'
import Axios from "axios";

export const actions: ActionTree<FeedbackState, RootState> = {
  async sendTourFeedback({commit, state, getters, dispatch, rootGetters}) {
    commit("setTransmitting", true)
    commit("setTransmittingError", false)
    try {
      const response = await Axios.post('/endpoints/tour/'+rootGetters['tour/tourId']+'/feedback', {
        staff_id: rootGetters['user/staffId'],
        text: getters.getText
      })
      commit("setTransmitting", false)
      commit("setText", "")
    } catch(e) {
      commit("setTransmitting", false)
      commit("setTransmittingError", true)
    }
  }
};
