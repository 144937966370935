<template>
  <b-modal
    :title="voucher && voucher.target_name"
    v-model="modalOpen"
    id="voucher-modal"
    modal-class="modal-fullscreen activity-form"
    @hidden="onModalHidden"
    @shown="onModalShown"
  >
    <div v-if="voucher">
      <SignModal
        :voucher="voucher"
        :main="voucher.id == mainVoucher.id"
        @signed="onSigned"
      ></SignModal>
      <template v-if="voucher.activities.length == 1">
        <b-row>
          <b-col md="6" cols="12" v-for="(attendance, index) in voucher.activities[0].attendances" :key="attendance.id">
            <b-form-group class="input-group">
              <float-label>
                <b-form-input
                  :ref="`voucherInput-0-${index}`"
                  type="number"
                  :placeholder="$const.ATTENDANCE_TYPES[attendance.type].description"
                  :value="attendance.quantity"
                  @input="onChangeAttendance(attendance, $event)"
                  v-on:keyup.enter="focusNextInput(0, index)"
                />
              </float-label>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-table-simple small class="mt-2 mb-2" striped hover>
          <b-thead>
            <b-tr>
              <b-th>Submit</b-th>
              <b-th>Status</b-th>
              <b-th>Tour</b-th>
              <b-th>Time</b-th>
              <b-th v-for="(type, key) in $const.ATTENDANCE_TYPES" :key="key">
                {{type.description}}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(activity, activityIndex) in voucher.activities" :key="activity.id">
              <b-td class="align-middle">
                <b-checkbox
                  :disabled="activity.status == 'confirmed' || activity.status == 'draft' && activitiesEnabled.length == 1"
                  v-model="activity.status"
                  unchecked-value="ignore"
                  value="draft"
                />
              </b-td>
              <b-td class="align-middle">
                <b-badge variant="info">{{activity.status}}</b-badge>
                <b-badge variant="success" class="ml-2" v-if="activity.tour_id == tour.id">ours</b-badge>
              </b-td>
              <b-td class="align-middle">
                {{activity.tour_name}}
                <b-badge variant="info" class="ml-2" v-if="activity.tour_nr">{{activity.tour_nr}}</b-badge>
              </b-td>
              <b-td class="align-middle">
                <span v-if="activity.target_time">{{activity.target_time | formatTime}}</span>
                <span v-else>-</span>
              </b-td>
              <b-td v-for="(attendance, index) in activity.attendances" :key="attendance.type">
                <template v-if="activity.status == 'draft'">
                  <b-form-input
                    type="number"
                    :placeholder="$const.ATTENDANCE_TYPES[attendance.type].label"
                    :value="attendance.quantity"
                    @input="onChangeAttendance(attendance, $event)"
                    :ref="`voucherInput-${activityIndex}-${index}`"
                    v-on:keyup.enter="focusNextInput(activityIndex, index)"
                  />
                </template>
                <template v-else>
                  <div class="form-control-plaintext">
                    {{attendance.quantity}}
                  </div>
                </template>

              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </template>


      <b-row class="pt-3">
        <b-col md="6" cols="12" v-for="signature in ['staff_signature', 'counter_signature']" :key="signature" v-if="signature === 'staff_signature' || !isMainVoucher">
          <b-form-group class="signature-group">
            <div class="signature-wrapper">
              <img :src="voucher[signature]" class="signature"/>
            </div>
            <b-form-text class="border-top">
              <template v-if="signature == 'staff_signature'">
                Staff
              </template>
              <template v-else>
                {{voucher.target_name}}
              </template>
            </b-form-text>
            <b-button size="sm" variant="outline-success" v-b-modal.signature-modal class="btn-sign" v-if="(signature === 'staff_signature' && isMainVoucher) || signature === 'counter_signature'">
              <fa icon="pen"/><span>&nbsp;Sign</span>
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
    <template #modal-footer class="w-100">
      <div class="text-right">
        <b-button variant="primary" @click="onSaveVoucher">Save</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script lang="ts">
  import {namespace} from 'vuex-class'
  import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
  import SignModal from './SignModal.vue'
  import {Tour, TourVoucherForm} from "@eguide/api";
  import * as _ from 'lodash'

  const tour = namespace('tour')
  const tours = namespace('tours')
  const user = namespace('user')
  const vouchers = namespace('tour/vouchers')

  @Component({components:{
    SignModal
  }})
  export default class TourVoucherModal extends Vue{

    modalOpen:boolean = false

    voucher: TourVoucherForm = null

    @tour.Getter tour: Tour
    @user.Getter staffId
    @vouchers.Mutation setActiveVoucherId
    @vouchers.Getter vouchers
    @vouchers.Getter mainVoucher
    @vouchers.Getter activeVoucher
    @vouchers.Action updateVoucher

    onChangeAttendance(attendance, quantity){
      const q = parseInt(quantity)
      if(isNaN(q)) attendance.quantity = null
      else attendance.quantity = q
    }

    onSigned(signature){
      if(this.isMainVoucher){
        this.voucher.staff_signature = signature
      } else {
        this.voucher.counter_signature = signature
      }
    }
    async onSaveVoucher(){
      await this.updateVoucher({id: this.voucher.id, data: {
        status: 'changed',
        staff_signature: this.voucher.staff_signature,
        counter_signature: this.voucher.counter_signature,
        activities: this.voucher.activities
      }})
      this.setActiveVoucherId(null)
      this.modalOpen = false
    }

    onModalShown(){
      this.voucher = {
        staff_signature: null,
        counter_signature: null,
        ..._.cloneDeep(this.activeVoucher)
      }
      if(!this.isMainVoucher && !this.voucher.staff_signature){
        this.voucher.staff_signature = this.mainVoucher.staff_signature
      }
    }

    onModalHidden(){
      this.voucher = null
      this.setActiveVoucherId(null)
    }

    getInputEl(activityIndex, attendanceIndex): HTMLInputElement {
      const refs = this.$refs[`voucherInput-${activityIndex}-${attendanceIndex}`]
      return refs ? refs[0].$el : null
    }

    focusNextInput(activityIndex, attendanceIndex) {
      let ref = this.getInputEl(activityIndex, attendanceIndex+1)
      if(!ref) ref = this.getInputEl(activityIndex+1, 0)
      if(!ref) return
      ref.focus()
    }

    get isMainVoucher() {
      return this.mainVoucher.id == this.voucher.id
    }

    get activitiesEnabled(){
      return this.voucher.activities.filter((a) => a.status == 'draft')
    }

  }
</script>

<style lang="sass">
  .activity-form
    img
      height: auto
      max-width: 100%
    input
      border-width: 0 0 1px 0
      border-radius: 0
    .input-group
      & > div
        width: 100%
    .vfl-label-on-input
      top: 3em !important
    .signature-group
      //border: solid 1px red
      .signature-wrapper
        //border: solid 1px yellow
        height: 100px
      .btn-sign
        position: absolute
        margin-top: -55px
      img.signature
        max-height: 100px
    .modal-footer
      position: fixed
      left: 0
      right: 0
      bottom: 0
      background-color: #fff
</style>
