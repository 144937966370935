import {GetterTree} from 'vuex'
import _ from 'lodash'
import {TourState} from './types'
import {RootState} from '../types'
import {Tour, TourStaff, TourStaffAttachment, TourVoucherForm, buildTourAttachments} from '@eguide/api'
import {flatten} from "@/apps/guide/util";

const coreBaseUrl = window.location.host == 'localhost:9100' ? 'http://localhost:9160' : ''
const uiBaseUrl = window.location.host == 'localhost:9100' ? 'http://localhost:9100' : ''


function changesIgnoreField(field: string){
  if(field == 'id' || field == 'updated_at' || field == 'status') return false
  else if(field.endsWith('_id') || field.endsWith('_at') || field.endsWith('.id') || field.endsWith('__typename')) return false
  else if(field.endsWith('.latitude') || field.endsWith('.longitude')) return false
  else return true
}

export const getters: GetterTree<TourState, RootState> = {

  tour(state, getters, rootState, rootGetters): Tour {
    return rootGetters['tours/tours'].find((t) => t.share_id == state.tourId)
  },

  tourVoucherForms(state, getters, rootState, rootGetters): TourVoucherForm[] {
    return getters.tour.voucher_forms
  },

  tourId(state, getters, rootState, rootGetters): string {
    return state.tourId
  },

  tourStaffId(state, getters, rootState, rootGetters): string {
    return state.tourStaffId
  },

  tourStaff(state, getters, rootState, rootGetters): string {
    return getters.tour.staff.find((s: TourStaff) => s.share_id == getters.tourStaffId)
  },

  tourStaffStatus(state, getters, rootState, rootGetters): string {
    if(!getters.tour) return null
    if(!getters.tour.staff_status) return null
    return getters.tour.staff_status.find((s) => s.share_id == getters.tourStaffId)
  },

  tourLoading({loading}): boolean{
    return loading
  },

  tourRemoteGone({remoteGone}): boolean {
    return remoteGone
  },

  tourChanges(state): object {
    const relevantChanges = {
      ...state.changes
    }
    //@ts-ignore
    delete relevantChanges.voucher_forms
    //@ts-ignore
    delete relevantChanges.request_forms
    //@ts-ignore
    delete relevantChanges.team
    //@ts-ignore
    delete relevantChanges.itinerary
    return relevantChanges
  },

  tourChangesFlat(state, getters): object {
    const flatChanges = flatten(getters.tourChanges)
    return _.pickBy(flatChanges, (value, field) => changesIgnoreField(field))
  },

  tourChangedFields(state, getters): string[] {
    return Object.keys(getters.tourChangesFlat)
  },

  tourChanged(state, getters): boolean {
    return getters.tourChangedFields.length > 0
  },

  tourDocumentsStatic(state, getters) {
    return buildTourAttachments(getters.tour, uiBaseUrl, coreBaseUrl)
  },

  tourDocumentsDynamic(state, getters) {
    return (getters?.tourStaff?.attachments||[]).map((attachment: TourStaffAttachment) => {
      return {
        id: attachment.asset.uuid,
        name: attachment.asset.name,
        url: `${coreBaseUrl}/endpoints/tour/${getters.tour.share_id}/asset/${attachment.asset.uuid}`
      }
    })
  },

  tourDocuments(state, getters){
    return [].concat(getters.tourDocumentsStatic, getters.tourDocumentsDynamic)
  },

  downloadState(state, getters) {
    return state.downloads
  }
};
