import Axios from 'axios'
import {Activity, Tour, TourRequestForm, TourStaff, TourVoucherForm} from "@eguide/api";


const axios = Axios.create({
  baseURL: window.location.host == 'localhost:9100' ? 'http://localhost:9160' : ''
})


const Api = {
  async getStatus() {
    const response = await axios.get('/endpoints/status')
    return response.data
  },
  async getTourData(id): Promise<Tour> {
    return (await axios.get('/endpoints/tour/'+id)).data
  },
  async acceptTourStaff(tourId, staffId) {
    const response = await axios.post('/endpoints/tour/'+tourId+'/accept', {
      staff_id: staffId
    })
    return response.data
  },
  async submitTourVoucher(tourId, staffId, voucher: TourVoucherForm) {
    const response = await axios.post('/endpoints/tour/'+tourId+'/voucher', {
      voucher: voucher,
      staff_id: staffId,
    })
    return response.data
  },
  async submitTourRequest(tourId, staffId, request: TourRequestForm) {
    const response = await axios.post('/endpoints/tour/'+tourId+'/request', {
      request_id: request.id,
      type: request.type,
      data: request.data,
    })
    return response.data
  },
  async updateLocation(locationId, data) {
    return (await axios.post('/endpoints/tour/location.update?id='+ locationId, {data:data})).data
  },
  async expandShortLink(input): Promise<string> {
    const result = await axios.post('/endpoints/short-link', {input: input})
    return result.data
  },
}

export default Api
